import React, { useContext } from 'react';
import { GlobalContext } from '../context/GlobalContextProvider';

export default function AiRecommendation() {
    const { recommendation, aiRecommendationPopupRef } = useContext(GlobalContext);

    const renderReasoning = (reasoning) => {
        if (typeof reasoning === 'string') {
            return <p>{reasoning}</p>;
        } else if (typeof reasoning === 'object' && reasoning !== null) {
            const { reasons_to_select = [], reasons_not_to_select = [] } = reasoning;
            return (
                <div>
                    <p className="font-semibold">Reasons to Select:</p>
                    <ul className="list-disc ml-6 mt-2">
                        {reasons_to_select.map((reason, index) => (
                            <li key={index} className="mb-2">
                                {reason}
                            </li>
                        ))}
                    </ul>

                    <br />

                    <p className="font-semibold">Reasons Not to Select:</p>
                    <ul className="list-disc ml-6 mt-2">
                        {reasons_not_to_select.map((reason, index) => (
                            <li key={index} className="mb-2">
                                {reason}
                            </li>
                        ))}
                    </ul>
                </div>
            );
        }

        return null;
    };

    return (
        <div className="fixed inset-0 flex justify-center items-center bg-[#24386c] bg-opacity-20 font-roboto">
            <div ref={aiRecommendationPopupRef} className="absolute bg-white p-4 rounded-md border border-[#24386c] shadow-lg w-[800px] h-[500px] overflow-auto">
                <p className='text-base font-semibold'>AI Recommendation</p>
                <table
                    className="text-left break-words overflow-hidden bg-[#eaeaea] text-gray-800 mt-4 text-sm"
                    cellPadding="2"
                    cellSpacing="0"
                    style={{ tableLayout: "fixed", width: "100%" }}
                >
                    <tbody>
                        <tr className="border-b-4 border-white text-sm">
                            <td className="p-1 font-semibold w-1/5"> Recommendation: </td>
                            <td className="p-1 w-4/5 text-sm"> {recommendation.rec} </td>
                        </tr>
                        <tr className="border-b-4 border-white">
                            <td className="p-1" colSpan="2">
                                <span className="font-semibold">Reasoning:</span>
                                <br />
                                <br />
                                {renderReasoning(recommendation.reasoning)}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

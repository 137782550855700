import React, { useContext, useMemo } from 'react'
import { useUtilManager } from '../util/UtilManager';
import { GlobalContext } from '../context/GlobalContextProvider';
import { POP_UP } from '../util/Constants';

export default function CandidateList(props) {
    const { isLoading, setPopup, setRecommendation } = useContext(GlobalContext);
    const { formatTs } = useUtilManager();

    const emptyMessage = useMemo(() => {
        if (isLoading) {
            return "Loading candidates...";
        }
        return props.emptyListMessage || "No candidates found!!!";
    }, [isLoading, props.emptyListMessage]);

    const colSpanForEmptyMessage = useMemo(() => {
        if (props.displayScore && props.displayRecommendation) {
            return 11;
        }
        if (props.displayScore && !props.displayRecommendation) {
            return 10;
        }
        return 9;
    }, [props.displayScore, props.displayRecommendation]);

    const getRecommendation = (candidate) => {
        if (candidate.recommendation === undefined) {
            return "---------";
        }
        return candidate.recommendation;
    };

    const handleRecommendationClick = (event, candidate) => {
        event.stopPropagation();
        let recommendation = {};
        recommendation.rec = candidate.recommendation;
        recommendation.reasoning = candidate.reasoning;
        setRecommendation(recommendation);
        setPopup(POP_UP.AI_RECOMMENDATION_POPUP);
    };

    return (
        <table
            className="font-roboto text-[11px] text-left align-top break-words overflow-hidden"
            cellPadding="2"
            cellSpacing="0"
            style={{ tableLayout: "fixed", width: "1180px" }}
        >
            <thead className="">
                <tr className="bg-[#dc244c] text-[#e8ddfe]">
                    <th className="w-[45px] font-semibold rounded-l-sm pl-1">Select</th>
                    <th className="w-[150px] font-semibold pl-1 pr-1">Name</th>
                    <th className="w-[250px] font-semibold pl-1 pr-1">Top Skills</th>
                    <th className="w-[100px] font-semibold pl-1 pr-1">Location</th>
                    <th className="w-[70px] font-semibold pl-1 pr-1">Experience</th>
                    <th className="w-[80px] font-semibold pl-1 pr-1">Visa</th>
                    <th className="w-[120px] font-semibold pl-1 pr-1">Work Preference</th>
                    <th className="w-[170px] font-semibold pl-1 pr-1">Posted By</th>
                    {props.displayScore && props.displayRecommendation && (
                        <>
                            <th className="w-[120px] font-semibold pr-1">Last Updated On</th>
                            <th className="w-[75px] font-semibold  pr-1">Score(%)</th>
                            <th className='w-[110px] font-semibold text-left pr-[2px] rounded-r-sm'>AI Recommendation</th>
                        </>
                    )}
                    {props.displayScore && !props.displayRecommendation && (
                        <>
                            <th className="w-[120px] font-semibold pr-1">Last Updated On</th>
                            <th className="w-[75px] font-semibold text-right pr-1 rounded-r-sm">Score(%)</th>
                        </>
                    )}
                    {!props.displayScore && !props.displayRecommendation && (
                        <>
                            <th className="w-[120px] font-semibold text-right pr-1 rounded-r-sm">Last Updated On</th>
                        </>
                    )}
                </tr>
            </thead>
            <tbody>
                {props.candidateList.length === 0 ? (
                    <tr>
                        <td colSpan={colSpanForEmptyMessage} className="font-roboto text-center text-gray-800 py-2 text-sm">
                            {emptyMessage}
                        </td>
                    </tr>
                ) : (
                    props.candidateList.map((candidate, index) => {
                        const rowNumber = index % 2 === 0;
                        let bgColor = rowNumber ? "bg-white" : "bg-gray-100";
                        if(props.submissions) {
                            const isSubmitted = props.submissions.includes(candidate.id);
                            if (isSubmitted) {
                                bgColor = "bg-blue-200";
                            }
                        }
                        const hoverBgColor = "hover:bg-[#dc244c]";
                        return (
                            <tr
                                key={`${candidate.id}-${candidate.recommendation}`}
                                className={`cursor-pointer ${bgColor} ${hoverBgColor} hover:bg-opacity-20 text-gray-700 hover:text-[#7532f9]`}
                                onClick={(event) => {
                                    if (event.target.classList.contains('select-column') || event.target.classList.contains('select-checkbox')) {
                                        return;
                                    }
                                    props.handleCandidateClick(candidate);
                                }}
                            >
                                <td className="select-column pl-3 pt-1">
                                    <input type="checkbox"
                                        checked={props.isCandidateSelected(candidate)}
                                        className="select-checkbox"
                                        onChange={() => props.handleCheckBoxChange(candidate)} />
                                </td>
                                <td className="text-[#7532f9] font-semibold pr-1 truncate align-top">{candidate.name}</td>
                                <td className="pl-1 pr-1 truncate align-top">{candidate.top_skills}</td>
                                <td className="pl-1 pr-1 truncate align-top">{candidate.loc}</td>
                                <td className="pl-1 pr-1 truncate align-top">{candidate.exp} Years</td>
                                <td className="pl-1 pr-1 truncate align-top">{candidate.visa}</td>
                                <td className="pl-1 pr-1 truncate align-top">{candidate.work_pref}</td>
                                <td className="pl-1 pr-1 truncate align-top">{candidate.uid}</td>
                                {props.displayScore && props.displayRecommendation && (
                                    <>
                                        <td className="pl-1 truncate align-top">{formatTs(candidate.ts)}</td>
                                        <td className="pr-1 truncate align-top">{candidate.score}</td>
                                        <td
                                            className={`pl-1 pr-[6px] truncate align-top text-center cursor-pointer ${candidate.recommendation !== undefined ? "font-semibold text-[#7532f9] hover:text-[#dc244c]" : ""}`}
                                            onClick={(event) => handleRecommendationClick(event, candidate)}
                                        >
                                            {getRecommendation(candidate)}
                                        </td>
                                    </>
                                )}
                                {props.displayScore && !props.displayRecommendation && (
                                    <>
                                        <td className="pl-1 truncate align-top">{formatTs(candidate.ts)}</td>
                                        <td className="pr-1 truncate align-top text-right">{candidate.score}</td>
                                    </>
                                )}
                                {!props.displayScore && !props.displayRecommendation && (
                                    <td className="pr-1 truncate align-top text-right">{formatTs(candidate.ts)}</td>
                                )}
                            </tr>
                        );
                    })
                )}
            </tbody>
        </table>
    );
}

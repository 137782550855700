import Navbar from "./components/module/Navbar";
import Footer from "./components/module/Footer";

import React, { useContext, useEffect } from "react";
import LoadingBar from "./components/module/LoadingBar";
import JobDetailsView from "./components/view/job/JobDetailsView";
import CandidateForm from "./components/view/candidate/CandidateForm";
import { POP_UP, VIEW } from "./components/util/Constants";
import { GlobalContext } from "./components/context/GlobalContextProvider";
import CandidateDetails from "./components/view/candidate/CandidateDetailsView";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import RouteHandler from "./components/util/RouteHandler";
import Login from "./components/view/user/Login";
import Signup from "./components/view/user/Signup";
import LoginPrompt from "./components/view/user/LoginPrompt";
/* eslint-disable no-unused-vars */
import log from "./components/util/Logger";
/* eslint-enable no-unused-vars */
import JobsView from "./components/view/job/JobsView";
import CandidatesView from "./components/view/candidate/CandidatesView";
import SubmissionView from "./components/view/SubmissionView";
import JobForm from "./components/view/job/JobForm";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UnsubscribeEmailView from "./components/view/UnsubscribeEmailView";
import ContactView from "./components/view/ContactView";
import AiRecommendation from "./components/view/AiRecommendation";
import KeywordCount from "./components/view/candidate/KeywordCount";

function App() {
    const location = useLocation();
    const currentPath = location.pathname;
    const navigate = useNavigate();
    const { currentView, popup, validateSession, isLoggedIn } = useContext(GlobalContext);

    const renderPopup = () => {
        switch (popup) {
            case POP_UP.ADD_JOB_POPUP:
            case POP_UP.UPDATE_JOB_POPUP:
                return <JobForm />;
            case POP_UP.UPDATE_CANDIDATE_POPUP:
            case POP_UP.ADD_CANDIDATE_POPUP:
                return <CandidateForm />;
            case POP_UP.AI_RECOMMENDATION_POPUP:
                return <AiRecommendation />;
            case POP_UP.COUNT_KEYWORD_POPUP:
                return <KeywordCount/>;
            default:
                return null;
        }
    };

    const renderView = () => {
        switch (currentView) {
            case VIEW.UNSUBSCRIBE_EMAIL:
                return <UnsubscribeEmailView />;
            case VIEW.SUBMISSIONS_TO_JOB:
            case VIEW.SUBMISSIONS_OF_JOB:
            case VIEW.SUBMISSIONS_TO_CANDIDATE:
            case VIEW.SUBMISSIONS_OF_CANDIDATE:
            case VIEW.SUBMIT_YOUR_CANDIDATES:
            case VIEW.SUBMIT_YOUR_JOBS:
            case VIEW.SUBMIT_TO_CANDIDATES:
            case VIEW.SUBMIT_TO_JOBS:
                return <SubmissionView />;
            case VIEW.JOB_SEARCH_RESULTS:
            case VIEW.ALL_JOBS:
                return <JobsView />;
            case VIEW.MY_JOBS:
                return <>{isLoggedIn ? <JobsView /> : <LoginPrompt />}</>;
            case VIEW.JOB_DETAILS:
                return <JobDetailsView />;
            case VIEW.CANDIDATE_DETAILS:
                return <CandidateDetails />;
            case VIEW.CANDIDATE_SEARCH_RESULTS:
            case VIEW.ALL_CANDIDATES:
                return <CandidatesView />;
            case VIEW.MY_CANDIDATES:
                return <>{isLoggedIn ? <CandidatesView /> : <LoginPrompt />}</>;
            case VIEW.LOGIN:
            case VIEW.LOGOUT:
                return <Login />;
            case VIEW.FORGOT_PASSWORD:
            case VIEW.SIGNUP:
                return <Signup />;
            case VIEW.CONTACT:
                return <ContactView />;
            default:
                return null;
        }
    };

    // useEffect(() => {
    //     if (!uid) {
    //         return;
    //     }

    //     let name = getNameFromUid(uid);
    //     if (userName) {
    //         name = userName;
    //     }
    //     window.CometChatWidget.init({
    //         appID: COMET_CHAT.APP_ID,
    //         appRegion: COMET_CHAT.REGION,
    //         authKey: COMET_CHAT.AUTH_KEY,
    //     }).then(
    //         () => {
    //             const chatUid = getChatUidFromUid(uid);
    //             const user = new window.CometChatWidget.CometChat.User(chatUid);
    //             user.setName(name);
    //             user.setMetadata({ email: uid });
    //             window.CometChatWidget.createOrUpdateUser(user).then(
    //                 () => {
    //                     updateCometUserContact(chatUid, uid);
    //                     window.CometChatWidget.login({ uid: chatUid }).then(
    //                         () => {
    //                             window.CometChatWidget.launch({
    //                                 widgetID: COMET_CHAT.WIDGET_ID,
    //                                 docked: "true",
    //                                 alignment: "right",
    //                                 roundedCorners: "false",
    //                                 height: "450px",
    //                                 width: "350px",
    //                                 defaultID: "sunitha_workcog_com",
    //                                 defaultType: "user",
    //                             });
    //                         },
    //                         (error) => {
    //                             console.log("User login failed with error:", error);
    //                         }
    //                     );
    //                 },
    //                 (error) => {
    //                     console.log("New target user create or update failed:", error);
    //                 }
    //             );
    //         },
    //         (error) => {
    //             console.log("Initialization failed with error:", error);
    //         }
    //     );
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [uid, userName]);

    useEffect(() => {
        const sid = localStorage.getItem("sid");
        if (sid !== null) {
            validateSession(sid);
        }
        const currentView = currentPath.split("/")[1];
        if (
            currentView === VIEW.ALL_JOBS ||
            currentView === VIEW.MY_JOBS ||
            currentView === VIEW.ALL_CANDIDATES ||
            currentView === VIEW.MY_CANDIDATES
        ) {
            navigate(`/${currentView}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="flex flex-col h-screen">
            <LoadingBar />
            <Navbar />
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <main className="flex flex-grow flex-col font-lato text-sm text-center items-center bg-white">
                <Routes>
                    <Route path="/:routeView?/:id?" element={<RouteHandler renderView={renderView} />} />
                </Routes>
                {renderPopup()}
                {/* {uid && <CometChatIframe />} */}
            </main>
            <Footer />
        </div>
    );
}

export default App;

import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../../context/GlobalContextProvider";
import { CandidateContext } from "../../context/CandidateContextProvider";
import { useUtilManager } from "../../util/UtilManager";

export default function KeywordCount() {
    const { keywordCountPopupRef } = useContext(GlobalContext);
    const { selectedCandidates, setSelectedCandidates } = useContext(CandidateContext);
    const textAreaRef = useRef(null);
    const [keywordCountMap, setKeywordCountMap] = useState({});
    const { fetchCandidateDetails } = useUtilManager();

    const handleInput = (e) => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = "auto";
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleKeywordCount();
        }
    };

    const handleKeywordCount = () => {
        const keywords = textAreaRef.current.value.split(",").map((keyword) => keyword.trim());
        const keywordCounts = keywords.reduce((acc, keyword) => {
            const regex = new RegExp(`\\b${keyword}\\b`, "gi");
            const matches = selectedCandidates[0].resume_file_text.match(regex);
            acc[keyword] = matches ? matches.length : 0;
            return acc;
        }, {});
        setKeywordCountMap(keywordCounts);
    };

    const setCandidateDetails = async (cand_id) => {
        let localCandidate = await fetchCandidateDetails(cand_id);
        setSelectedCandidates([localCandidate]);
    };


    useEffect(() => {
        if (selectedCandidates.length === 0) {
            return;
        }
        if (!selectedCandidates[0].resume_file_text) {
            setCandidateDetails(selectedCandidates[0].id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCandidates]);

    return (
        <div className="fixed inset-0 flex justify-center items-center bg-[#24386c] bg-opacity-20 font-roboto">
            <div
                ref={keywordCountPopupRef}
                className="absolute bg-white p-4 rounded-md border border-[#24386c] shadow-lg w-[700px] h-[500px] overflow-auto"
            >
                <p className="text-base font-semibold">Count keywords in {selectedCandidates[0].name} resume</p>
                <textarea
                    rows="1"
                    placeholder={`Enter comma separated keywords, e.g. Java, Python, React`}
                    className="mt-2 shadow-md w-full bg-white rounded-xl pl-3 pr-3 py-2 overflow-hidden focus:outline-none focus:ring-0 text-gray-700 resize-none border border-[#24386c] border-opacity-30"
                    onInput={handleInput}
                    onKeyDown={handleKeyDown}
                    ref={textAreaRef}
                ></textarea>
                <button
                    onClick={handleKeywordCount}
                    className="bg-[#24386c] hover:bg-[#dc244c] py-1 px-2 rounded-sm text-[#e8ddfe] mt-4 font-semibold font-roboto text-xs"
                >
                    Get Count
                </button>
                {Object.keys(keywordCountMap).length > 0 && (
                    <div className="flex justify-center mt-10">
                        <table className="w-3/4 text-sm border border-gray-300">
                            <thead>
                                <tr className="bg-gray-200">
                                    <th className="border border-gray-300 px-2 py-1 text-center">Keyword</th>
                                    <th className="border border-gray-300 px-2 py-1 text-center">Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(keywordCountMap).map(([keyword, count]) => (
                                    <tr key={keyword}>
                                        <td className="border border-gray-300 px-2 py-1 font-semibold text-center text-gray-700">
                                            {keyword}
                                        </td>
                                        <td className="border border-gray-300 px-2 py-1 text-center">{count}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
}

export const VIEW = {
    ALL_JOBS: "all_jobs",
    ALL_CANDIDATES: "all_candidates",
    MY_JOBS: "my_jobs",
    MY_CANDIDATES: "my_candidates",
    BLOG: "blog",
    JOB_SEARCH_RESULTS: "job_search_results",
    SIGNUP: "signup",
    LOGIN: "login",
    LOGOUT: "logout",
    FORGOT_PASSWORD: "forgot_password",
    CANDIDATE_SEARCH_RESULTS: "candidate_search_results",
    JOB_DETAILS: "job_details",
    CANDIDATE_DETAILS: "candidate_details",
    SUBMIT_TO_CANDIDATES: "submit_to_candidates",
    SUBMIT_YOUR_JOBS: "submit_your_jobs",
    SUBMIT_TO_JOBS: "submit_to_jobs",
    SUBMIT_YOUR_CANDIDATES: "submit_your_candidates",
    UNSUBSCRIBE_EMAIL: "unsubscribe_email",
    SUBMISSIONS_TO_JOB: "submission_to_job",
    SUBMISSIONS_OF_JOB: "submission_of_job",
    SUBMISSIONS_TO_CANDIDATE: "submission_to_candidate",
    SUBMISSIONS_OF_CANDIDATE: "submission_of_candidate",
    CONTACT: "contact",
};

export const POP_UP = {
    ADD_CANDIDATE_POPUP: "add_candidate_popup",
    UPDATE_CANDIDATE_POPUP: "update_candidate_popup",
    ADD_JOB_POPUP: "add_job_popup",
    UPDATE_JOB_POPUP: "update_job_popup",
    AI_RECOMMENDATION_POPUP: "ai_recommendation_popup",
    COUNT_KEYWORD_POPUP: "count_keyword_popup",
    NONE: 'none'
};

export const WARNING = {
    LOGIN_TO_CHAT: "Please login to chat!",
    SELECT_A_JOB_TO_CHAT: "Please select a job to chat with recruiter!",
    SELECT_A_CANDIDATE_TO_CHAT: "Please select a candidate to chat with vendor!",
    NOT_ALLOWED_TO_CHAT_OTHER_JOB: "You are not allowed to send message for jobs posted by others!",
    NOT_ALLOWED_TO_CHAT_OTHER_CANDIDATE: "You are not allowed to send message for candidates posted by others!",
};

export const BUTTON_LABEL = {
    CHAT_WITH_RECRUITER: "Chat with Recruiter",
    CHAT_WITH_VENDOR: "Chat with Vendor"
};

export const COMET_CHAT = {
    APP_ID: '2662651c692d6cb5',
    REGION: 'in',
    AUTH_KEY: '3fa62c5b89e7a61722bfef5c5af99c36c5fc984f',
    WIDGET_ID: '1a60d7bd-d9de-47cf-9857-0e81fe3c2d2d'
};